/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

const Section = styled.section`
  & .container {
    @media (max-width: 767px) {
      padding-right: 0 !important;
      margin-right: 0 !important;
      max-width: unset !important;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (min-width: 992px) {
      font-size: 36px;
    }
    @media (max-width: 991.98px) {
      font-size: 20px;
    }
    @media (max-width: 767px) {
      padding-right: 0.75rem !important;
    }
  }
`

const Product = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 768px) {
    height: 100%;
    width: 100%;
    min-height: 320px;
  }

  @media (max-width: 767px) {
    height: 275px;
    width: 100%;
  }

  @media (max-width: 575px) {
    height: 200px;
    width: 100%;
  }
`

const Links = styled.div``

const Col = styled.div`
  @media (min-width: 1200px) {
    height: 330px;
  }
  @media (max-width: 1199px) {
    height: 320px;
  }
  @media (max-width: 767px) {
    height: 220px;
    width: 100%;
  }
  @media (max-width: 575px) {
    height: 220px;
  }
`

interface ProductsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Products
}

const Products: React.FC<ProductsProps> = ({ fields }) => (
  <Section className="mb-5 pb-lg-5" id={fields.sectionid}>
    <div className="container">
      <Content content={fields.description} className="mb-5 mt-5" />
    </div>
    <div className="container">
      <div className="d-none d-md-flex">
        <Desktop fields={fields} />
      </div>
      <div className="d-flex d-md-none">
        <Mobile fields={fields} />
      </div>
    </div>
  </Section>
)

interface DesktopProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Products
}

const Desktop: React.FC<DesktopProps> = ({ fields }) => (
  <div className="row w-100 justify-content-center gx-0">
    {fields.products?.map((p, productIndex) => {
      const [currentIndex, setCurrentIndex] = useState<number>(0)

      return (
        <div className="col-lg-6 mb-4" key={productIndex}>
          <Product className="row gx-0 mx-3 h-100 flex-column flex-xl-row ">
            <Col
              className={`col-xl-6 px-0 ${
                p?.imageposition === 'right' ? 'order-xl-2' : ''
              }`}
            >
              <AnimatePresence>
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: '100%' }}
                  exit={{ height: 0 }}
                  transition={{
                    duration: 0.8,
                    type: 'spring',
                  }}
                >
                  <Image
                    image={p?.links![currentIndex]?.image}
                    alt=""
                    className="h-100 w-100"
                  />
                </motion.div>
              </AnimatePresence>
            </Col>
            <div className="col-xl-6 px-0">
              <Links className="p-4 h-100 d-flex flex-column justify-content-center">
                <h2 className="mb-4">{p?.title}</h2>
                <div>
                  {p?.links?.map((l, linkIndex) => (
                    <motion.div
                      whileHover={() => setCurrentIndex(linkIndex)}
                      key={linkIndex}
                    >
                      <AnchorPrimary
                        href={l?.link?.url || '#'}
                        className="mb-2"
                      >
                        {l?.link?.title}
                      </AnchorPrimary>
                    </motion.div>
                  ))}
                </div>
              </Links>
            </div>
          </Product>
        </div>
      )
    })}
  </div>
)

const SliderWrapper = styled.div`
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 70%;
  }
`

interface MobileProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Products
}

const Mobile: React.FC<MobileProps> = ({ fields }) => (
  <SliderWrapper className="mt-4 w-100">
    {fields?.products && fields.products?.length > 1 && (
      <Swiper
        slidesPerView="auto"
        spaceBetween={40}
        freeMode
        freeModeMomentum={false}
        touchMoveStopPropagation
      >
        {fields.products?.map((p, index) => (
          <SwiperSlide key={index}>
            <Product className="position-relative h-100">
              <Image image={p?.links![0]?.image} alt="" />
              <div>
                <Links className="p-2">
                  <h2 className="mb-4">{p?.title}</h2>
                  <div>
                    {p?.links?.map((l, linkIndex) => (
                      <AnchorPrimary
                        href={l?.link?.url || '#'}
                        key={linkIndex}
                        className="mb-2"
                      >
                        {l?.link?.title}
                      </AnchorPrimary>
                    ))}
                  </div>
                </Links>
              </div>
            </Product>
          </SwiperSlide>
        ))}
      </Swiper>
    )}
    {fields.products?.length === 1 && (
      <Product className="row gx-0 mx-3 h-100 flex-column flex-xl-row ">
        <Col
          className={`col-xl-6 px-0 w-100 ${
            fields.products[0]?.imageposition === 'right' ? 'order-xl-2' : ''
          }`}
        >
          <AnimatePresence>
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: '100%' }}
              exit={{ height: 0 }}
              transition={{
                duration: 0.8,
                type: 'spring',
              }}
            >
              <Image
                image={fields.products[0]?.links![0]?.image}
                alt=""
                className="h-100 w-100"
              />
            </motion.div>
          </AnimatePresence>
        </Col>
        <div className="col-xl-6 px-0">
          <Links className="p-4 h-100 d-flex flex-column justify-content-center">
            <h2 className="mb-4">{fields.products[0]?.title}</h2>
            <div>
              {fields.products[0]?.links?.map((l, linkIndex) => (
                <AnchorPrimary
                  href={l?.link?.url || '#'}
                  key={linkIndex}
                  className="mb-2"
                >
                  {l?.link?.title}
                </AnchorPrimary>
              ))}
            </div>
          </Links>
        </div>
      </Product>
    )}
  </SliderWrapper>
)

export default Products
